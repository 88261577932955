import { ClientError } from "graphql-request";
import { UseFormMethods } from "react-hook-form";

interface FieldError {
  [key: string]: string;
}

interface ValidationError {
  message: string;
  fields?: FieldError;
}

interface APIErrors {
  response: {
    errors: ClientError["response"] | undefined;
  };
}

export const formatApiErrors = (
  { response }: APIErrors,
  setError: UseFormMethods["setError"]
) => {
  if (!response || !response.errors || response.errors?.length === 0) {
    return {};
  }

  const graphQLError = response.errors[0] as ValidationError;

  if (graphQLError.message !== "VALIDATION_FAILED") {
    return {};
  }

  const errors = graphQLError && graphQLError.fields;

  if (errors) {
    Object.keys(errors).forEach((key) => {
      setError(key, {
        type: "ApiError",
        message: errors[key] || "",
      });
    });
  }

  return errors;
};
