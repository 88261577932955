import React, { useEffect } from "react";
import { t, Trans } from "@helpers/translate";
import { useRouter } from "next/router";
import Head from "@components/common/Head";
import { Container } from "@components/ui/Container";
import { Title } from "@components/ui/Title";
import LoginForm from "@components/pages/Login";
import { useAuth } from "@helpers/hooks/useAuth";

const Page = () => {
  const router = useRouter();
  const { logout } = useAuth();

  useEffect(() => {
    if (router.asPath.includes("unauthenticated")) {
      logout();
    }
  }, []);

  return (
    <>
      <Head title={t({ id: "login.seo.title", message: "Log In" })} />
      <Container className="max-w-2xl py-20">
        <Title className="text-center mb-20">
          <Trans id="login.h1">Log In</Trans>
        </Title>

        <LoginForm redirectTo={(router.query.redirectTo as string) || ""} />
      </Container>
    </>
  );
};

export default Page;
