import React, { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const Divider: FC<{ className?: string; children?: ReactNode }> = ({
  children,
  className = "",
}) => {
  const classes = twMerge(
    "border-t text-center relative w-full flex justify-center my-12",
    className
  );

  return (
    <div className={classes}>
      {children && (
        <div className="absolute -mt-3 bg-white px-4 mx-auto">{children}</div>
      )}
    </div>
  );
};
