import { t } from "@helpers/translate";
import { ValidationError } from "yup";

export interface TData {
  [key: string]: string | number;
}

const translate = (_key: string, params?: TData) => {
  // const type = (params && params.value && typeof params.value) || "default";
  const value = params && params.value;
  const key = _key === "typeError" ? "required" : _key;

  const field =
    params && params.label
      ? params.label
      : t({ id: "validations.generic.field" });

  const mixedKeys = [
    "default",
    "required",
    "oneOf",
    "notOneOf",
    "notType",
    "defined",
  ];

  const values = { ...params, field };

  // mixed
  if (mixedKeys.includes(key)) {
    if (key === "default") {
      return t({ id: "validations.mixed.default" });
    }

    if (key === "required") {
      return t({ id: "validations.mixed.required", values });
    }

    if (key === "oneOf") {
      return t({ id: "validations.mixed.oneOf", values });
    }

    if (key === "notOneOf") {
      return t({ id: "validations.mixed.notOneOf", values });
    }

    if (key === "notType") {
      return t({ id: "validations.mixed.notType", values });
    }

    if (key === "defined") {
      return t({ id: "validations.mixed.defined", values });
    }
  }

  // Date
  if (typeof value === "object" && Object(value) instanceof Date) {
    if (key === "min") {
      return t({ id: "validations.date.min", values });
    }

    if (key === "max") {
      return t({ id: "validations.date.max", values });
    }
  }

  if (typeof value === "string") {
    if (key === "length") {
      return t({ id: "validations.string.length", values });
    }

    if (key === "min" && params?.min === 1) {
      return t({ id: "field.validation.error.atleast-one-char" });
    }

    if (key === "min") {
      return t({ id: "validations.string.min", values });
    }

    if (key === "max") {
      return t({ id: "validations.string.max", values });
    }

    if (key === "matches") {
      return t({ id: "validations.string.matches", values });
    }

    if (key === "email") {
      return t({ id: "validations.string.email", values });
    }

    if (key === "url") {
      return t({ id: "validations.string.url", values });
    }

    if (key === "trim") {
      return t({ id: "validations.string.trim", values });
    }

    if (key === "lowercase") {
      return t({ id: "validations.string.lowercase", values });
    }

    if (key === "uppercase") {
      return t({ id: "validations.string.uppercase", values });
    }
  }

  if (typeof value === "number") {
    if (key === "min") {
      return t({ id: "validations.number.min", values });
    }

    if (key === "max") {
      return t({ id: "validations.number.max", values });
    }

    if (key === "lessThan") {
      return t({ id: "validations.number.lessThan", values });
    }

    if (key === "moreThan") {
      return t({ id: "validations.number.moreThan", values });
    }

    if (key === "notEqual") {
      return t({ id: "validations.number.notEqual", values });
    }

    if (key === "positive") {
      return t({ id: "validations.number.positive", values });
    }

    if (key === "negative") {
      return t({ id: "validations.number.negative", values });
    }

    if (key === "integer") {
      return t({ id: "validations.number.integer", values });
    }
  }

  return (params && params.message) || key;
};

export const formatYupErrors = (err: ValidationError) => {
  const errors: TData = {};
  err.inner.forEach((e) => {
    if (e.path) {
      errors[e.path] = translate(e.type || "", {
        ...e.params,
        message: e.message,
      } as TData);
    }
  });

  return errors;
};
