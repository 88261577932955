import { formatYupErrors } from "@helpers/validations/formatYupErrors";
import { useCallback, useMemo } from "react";
import * as yup from "yup";

const useYupValidationResolver = <T extends Record<string, unknown>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any
) => {
  const schema = useMemo(() => yup.object().shape(rules), []);

  return useCallback(
    async (data: T | null | undefined) => {
      try {
        const values = await schema.validateSync(data, { abortEarly: false });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: formatYupErrors(errors),
        };
      }
    },
    [schema]
  );
};

export default useYupValidationResolver;
