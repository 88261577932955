import React, { FC, ReactNode } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { t } from "@helpers/translate";
import { GOOGLE_OAUTH_URI } from "@constants";

export interface GoogleAuthResponse {
  email: string;
  family_name: string;
  given_name: string;
  hd: string;
  id: string;
  locale: string;
  name: string;
  picture: string;
  verified_email: boolean;
}

interface GoogleLoginProps {
  onSuccess: (res: GoogleAuthResponse) => void;
  children?: ReactNode;
}

export const GoogleLogin: FC<GoogleLoginProps> = ({ onSuccess, children }) => {
  const googleLogin = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (codeResponse) => {
      const oauthUri = `${GOOGLE_OAUTH_URI}${codeResponse.access_token}`;
      const res = await axios.get<GoogleAuthResponse>(oauthUri);
      onSuccess(res.data);
    },
  });

  return (
    <div className=" w-full">
      <a
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
        className="bg-white text-gray-800 cursor-pointer w-full py-2 px-4 text-lg rounded-full flex justify-center items-center border border-gray-400 hover:border-gray-500 focus:outline-none focus:shadow-outline"
        onClick={() => googleLogin()}
      >
        {children}
      </a>
    </div>
  );
};
