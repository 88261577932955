import Link from "next/link";
import React, { FC, ReactNode } from "react";
import { t } from "@helpers/translate";

export const TextLink: FC<{
  href: string;
  target?: string;
  children?: ReactNode;
}> = ({ children, href, target = "_self" }) => {
  return (
    <Link
      href={href}
      title={t({
        id: "common.link.title",
        message: "GoWithGuide - Private Tours & Local Tour Guides",
      })}
      target={target}
      className="link"
    >
      {children}
    </Link>
  );
};
