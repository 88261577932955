import React, { FC, ReactNode } from "react";
import { FACEBOOK_APP_ID } from "@constants";
import Facebook, { ReactFacebookLoginInfo } from "react-facebook-login";
import Icon from "../Icon";

interface FacebookLoginProps {
  callback: (res: ReactFacebookLoginInfo) => void;
  fields?: string;
  children?: ReactNode;
}

export const FacebookLogin: FC<FacebookLoginProps> = ({
  callback,
  children,
  fields = "name",
}) => {
  return (
    <Facebook
      appId={FACEBOOK_APP_ID}
      size="small"
      fields={fields}
      isMobile={false}
      textButton={children as string}
      cssClass="bg-secondary hover:bg-secondary-dark text-white w-full p-3 text-lg rounded-full flex justify-center items-center"
      icon={<Icon icon="facebook" size="sm" className="mr-4 text-white" />}
      callback={callback}
    />
  );
};
