import React, { FC } from "react";
import { useForm } from "react-hook-form";
import {
  AuthenticateInput,
  AuthenticateWithFacebookInput,
  AuthenticateWithGoogleInput,
  AuthRequestType,
} from "@graphql/types";
import { graphql } from "@helpers/graphql";
import { formatApiErrors } from "@helpers/validations";
import { t, Trans } from "@helpers/translate";
import Link from "next/link";
import { useAuth } from "@helpers/hooks/useAuth";
import useSubmitting from "@helpers/hooks/useSubmitting";
import useYupValidationResolver from "@helpers/hooks/useYupValidationResolver";
import * as yup from "yup";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { GOOGLE_CLIENT_ID, ROUTE_SUPPLIER_APPLY } from "@constants";
import { useRouter } from "next/router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleAuthResponse, GoogleLogin } from "@components/ui/GoogleLogin";
import { Form, FormGroup, Input } from "@components/ui/Form";
import { Button } from "@components/ui/Button";
import { Divider } from "@components/ui/Divider";
import Icon from "@components/ui/Icon";
import { FacebookLogin } from "@components/ui/FacebookLogin";
import { TextLink } from "@components/ui/TextLink";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
type FacebookUser = ReactFacebookLoginInfo & {
  first_name?: string;
  last_name?: string;
};

type Prop = {
  switchSignUp?: () => void | undefined;
  onFinish?: () => Promise<void> | Promise<undefined>;
  redirectTo?: string;
  customAction?: boolean;
};

const rules = {
  email: yup.string().email().required(),
  password: yup.string().required(),
};

const LoginForm: FC<Prop> = ({
  switchSignUp,
  onFinish,
  redirectTo,
  customAction = false,
}) => {
  const { query } = useRouter();
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: useYupValidationResolver(rules),
  });
  const { login, localLogin } = useAuth();
  const [isSubmitting, setSubmitting] = useSubmitting(false);

  const onSubmit = handleSubmit((input: AuthenticateInput) => {
    setSubmitting(true);
    graphql
      .Authenticate({ input })
      .then(async (data) => {
        if (customAction && onFinish && !redirectTo) {
          await localLogin(data?.Authenticate.token);
          await onFinish();
          setSubmitting(false);
        } else if (query.supplier) {
          login(data?.Authenticate.token, ROUTE_SUPPLIER_APPLY);
          return;
        } else {
          await login(data?.Authenticate.token, redirectTo || "/");
          onFinish && (await onFinish());
        }
      })
      .catch((errors) => {
        setSubmitting(false);
        formatApiErrors(errors, setError);
      });
  });

  const onFacebookLogin = (res: FacebookUser) => {
    setSubmitting(true);

    const input: AuthenticateWithFacebookInput = {
      firstName: res.first_name ?? "",
      lastName: res.last_name || res.first_name || "",
      email: res.email ?? "",
      facebookId: res.id,
      request: AuthRequestType.Signin,
    };

    if (Object.values(input).some((val) => val === undefined || val === "")) {
      setSubmitting(false);
      setError("facebookId", {
        type: "ApiError",
        message: t({
          id: "auth.missing.field.signin.facebook",
          message:
            "Facebook did not provide the information (such as your name or Facebook ID) needed to Sign In (or Log in). Please enter your email address and password to Sign In (or Log in).",
        }),
      });
      return;
    }

    graphql
      .AuthenticateWithFacebook({ input })
      .then(async (data) => {
        const token = data?.AuthenticateWithFacebook.token;
        if (customAction && onFinish && !redirectTo) {
          setSubmitting(false);
          await localLogin(token);
          onFinish();
        } else if (query.supplier) {
          login(token, ROUTE_SUPPLIER_APPLY);
          return;
        } else {
          await localLogin(token);
          onFinish && onFinish();
          await login(token, redirectTo || "/");
        }
      })
      .catch((errors) => {
        setSubmitting(false);
        formatApiErrors(errors, setError);
      });
  };

  const onGoogleLogin = (res: GoogleAuthResponse) => {
    setSubmitting(true);

    const input: AuthenticateWithGoogleInput = {
      firstName: res.given_name,
      lastName: res.family_name || res.given_name,
      email: res.email,
      googleId: res.id,
      request: AuthRequestType.Signin,
    };

    if (Object.values(input).some((val) => val === undefined || val === "")) {
      setSubmitting(false);
      setError("googleId", {
        type: "ApiError",
        message: t({
          id: "auth.missing.field.signin.google",
          message:
            "Google did not provide the information (such as your name or Google ID) needed to Sign In (or Log in). Please enter your email address and password to Sign In (or Log in).",
        }),
      });
      return;
    }

    graphql
      .AuthenticateWithGoogle({ input })
      .then(async (data) => {
        const token = data?.AuthenticateWithGoogle.token;
        if (customAction && onFinish && !redirectTo) {
          setSubmitting(false);
          await localLogin(token);
          onFinish();
        } else if (query.supplier) {
          login(token, ROUTE_SUPPLIER_APPLY);
          return;
        } else {
          await localLogin(token);
          onFinish && onFinish();
          await login(token, redirectTo || "/");
        }
      })
      .catch((errors) => {
        setSubmitting(false);
        formatApiErrors(errors, setError);
      });
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Form onSubmit={onSubmit}>
        <FormGroup
          title={t({ id: "login.field.email", message: "Email" })}
          error={errors.email}
        >
          <Input name="email" ref={register} disabled={isSubmitting} />
        </FormGroup>

        <FormGroup
          title={t({ id: "login.field.password", message: "Password" })}
          error={errors.password}
        >
          <Input
            name="password"
            type="password"
            ref={register}
            disabled={isSubmitting}
          />

          <Link
            href={getAbsoluteUrl("/recover")}
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
            target={customAction ? "_blank" : "_self"}
            className="w-full text-right block text-sm underline text-link hover:text-link-dark absolute top-0 right-0 mt-22 pt-1"
          >
            <Trans id="login.recover-password">Recover Password</Trans>
          </Link>
        </FormGroup>

        <Button
          type="submit"
          className="w-full mt-10"
          variant="golden-yellow"
          disabled={isSubmitting}
          fontLight
          rounded
        >
          <Trans id="login.button.submit">Log In</Trans>
        </Button>

        <Divider>
          <Trans id="login.divider">or</Trans>
        </Divider>

        <FormGroup error={errors.googleId}>
          <GoogleLogin onSuccess={onGoogleLogin}>
            <Icon icon="google" size="sm" className="mr-4" />
            <Trans id="login.button.google">Login With Google</Trans>
          </GoogleLogin>
        </FormGroup>

        <FormGroup error={errors.facebookId}>
          <FacebookLogin
            callback={onFacebookLogin}
            fields="name,email,first_name,last_name"
          >
            <Trans id="login.button.facebook">Login With Facebook</Trans>
          </FacebookLogin>
        </FormGroup>

        {switchSignUp && (
          <p className="text-center mt-10 text-gray-500">
            <Trans id="login.no-account">
              No Account Yet?{" "}
              {customAction && switchSignUp ? (
                <span
                  className="cursor-pointer text-link"
                  onClick={switchSignUp}
                >
                  Sign Up
                </span>
              ) : (
                <TextLink href={getAbsoluteUrl("/signup")}>Sign Up</TextLink>
              )}
            </Trans>
          </p>
        )}
      </Form>
    </GoogleOAuthProvider>
  );
};

export default LoginForm;
