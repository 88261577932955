import { useState } from "react";

const freezeClick = (e: Event) => {
  e.stopPropagation();
  e.preventDefault();
};

type Payload = [boolean, (value: boolean) => void];

const useSubmitting = (value = false): Payload => {
  const [isSubmitting, setSubmitting] = useState(value);

  const _setSubmitting = (value: boolean) => {
    if (value) {
      document.addEventListener("click", freezeClick, true);
    } else {
      document.removeEventListener("click", freezeClick, true);
    }

    setSubmitting(value);
  };

  return [isSubmitting, _setSubmitting];
};

export default useSubmitting;
